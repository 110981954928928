import React, { useState, useEffect } from 'react';

import Speech from 'speak-tts';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { BTNPlay, BTNPause, BTNAudio } from '../../Atoms/Icons/Icons';

import './AudioComponent.scss';
let classNames = require('classnames');

const AudioComponent = ({ text, textToRead, color }) => {
  const intl = useIntl();
  const [playing, setPlaying] = useState(false);
  const [speech, setSpeech] = useState(false);
  const [speechPlay, setSpeechPlay] = useState(false);
  const [readText, setReadText] = useState('');
  const [mouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    setReadText(textToRead);
  }, [textToRead]);

  useEffect(() => {
    setSpeech(new Speech());
    return () => { if (speech) { speech.pause(); speech.resume() } };
  }, []);

  useEffect(() => {
    if (!speech) { return false }

    speech.init({
      volume: 0.5,
      lang: intl.locale === 'fr' ? 'fr-FR' : 'en-EN',
      rate: 1,
      pitch: 1
    })
      .catch(e => {
        console.error('An error occured while initializing : ', e);
      });
    return () => { speech.pause() };
  }, [speech]);

  const toggle = (e) => {
    e.preventDefault();
    if (!speech) { return false }
    if (playing) {
      speech.pause();
    } else {
      speech
        .speak({
          text: readText.replaceAll('\n', ' '),
          queue: false,
          listeners: {
            onstart: () => {
              setSpeechPlay(true);
            },
            onend: () => {
              setSpeechPlay(false);
            },
            onresume: () => {
              setSpeechPlay(false);
            },
            onboundary: event => {
              // console.log(
              //   event.name +
              //   ' boundary reached after ' +
              //   event.elapsedTime +
              //   ' milliseconds.'
              // );
            }
          }
        })
        .then(data => {
          //console.log('Success !', data);
        })
        .catch(ev => {
          console.error('An error occurred :', ev);
        });
    }

    setPlaying(!playing);
  };

  return (
    <div className={classNames('audio_component')}>
      {
        <div className="default_state">
          <div className="btns">
            <a
              href="#"
              role='button'
              onClick={toggle}
              onMouseOver={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              aria-label={intl.formatMessage({ id: !playing ? 'ariaLabel.listen_audio_page' : 'ariaLabel.stop_audio_page' })}
              data-paused={playing ? 'false' : 'true'}
            >
              {
                playing ? (
                  <span>
                    <BTNPause />
                  </span>
                ) : (
                  <>
                    {
                      mouseOver ? (
                        <span>
                          <BTNPlay />
                        </span>
                      ) : (
                        <span>
                          <BTNAudio />
                        </span>
                      )
                    }
                  </>
                )
              }
            </a>
          </div>
          {
            playing ?
              <div className="progress_bar">
                <span className={classNames({ 'animate': speechPlay })}></span>
              </div>
              :
              <p className={color ? color : ''} aria-hidden="true">{text}</p>
          }

        </div>
      }
    </div >
  );
};

AudioComponent.default = {
  text: 'Lister Audio Version',
  src: ''
};

export default AudioComponent;
